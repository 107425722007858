import React from "react"
import Seo from "../components/molecules/Seo"
import Splash from "../components/organisms/Splash"
import ContentHolder from "../components/organisms/ContentHolder"
import Image from "../components/atoms/Image"
import WorkTextBlock from "../components/molecules/WorkTextBlock"
import RelatedWork from "../components/organisms/RelatedWork"
import WorkImage from "../components/molecules/WorkImage"
import ArrowText from "../components/atoms/ArrowText"
import WorkMultipleImage from "../components/molecules/WorkMultipleImage"
import Numbers from "../components/organisms/Numbers"
import Number from "../components/molecules/Number"
import thumb from "../../static/images/mirvac-new-00001.jpg"

function Mirvac() {
  return (
    <>
      <Seo
        title="Innovating the retail guest experience | Craig Walker x Mirvac"
        description="Craig Walker was asked to design a service experience that would not only be unique to the Broadway Shopping Centre, but that would also clearly differentiate the centre from its competitors."
        image={thumb}
      />
      <Splash
        text={<>Innovating the retail guest&nbsp;experience</>}
        work={true}
      >
        <p>
          <b>
            Broadway Shopping Centre in Sydney is Mirvac’s busiest and best
            performing asset. Following a recent interior renovation of the
            centre, Mirvac saw the need to upgrade its guest experience.
          </b>
        </p>

        <p>
          Craig Walker was asked to design a service experience that would not
          only be unique to the Broadway Shopping Centre, but that would also
          clearly differentiate the centre from its competitors.
        </p>
        <ArrowText>
          <p>Learn More</p>
        </ArrowText>
      </Splash>

      <ContentHolder>
        <WorkImage>
          <Image imgName="mirvac-new-1.jpg" />
        </WorkImage>

        <WorkTextBlock>
          <h2>Integrated and&nbsp;layered</h2>
          <p>
            We deployed a range of ethnographic research approaches, including
            customer walk&#x2011;alongs, intercepts, interviews and workshops.
          </p>

          <p>
            From the insights gained, we designed a layered, integrated service
            model that moves away from the traditional ‘service desk’ approach.
          </p>
        </WorkTextBlock>

        <WorkMultipleImage
          images={["mirvac-new-00001.jpg", "mirvac-new-00002.jpg"]}
        />

        <Numbers>
          <Number
            number={31}
            text="Hours of observations and customer walk&#x2011;alongs"
          />
          <Number number={8} text="Service safaris" />
          <Number number={20} text="Experiences designed" />
        </Numbers>

        <WorkMultipleImage
          images={[
            "mirvac-storyboard-00001.jpg",
            "mirvac-storyboard-00011.jpg",
            "mirvac-storyboard-00010.jpg",
          ]}
        />

        <WorkMultipleImage
          images={[
            "mirvac-storyboard-00006.jpg",
            "mirvac-storyboard-00005.jpg",
            "mirvac-storyboard-00004.jpg",
          ]}
        />

        <WorkTextBlock>
          <h2>A new normal</h2>
          <p>
            Designed during the COVID19 pandemic, the model brings together a
            wide range of services from across the centre, such as delivery,
            food and beverage, environment and parking, all supported by digital
            technology and strategic partnerships.
          </p>

          <p>
            The model, which we delivered with a set of experience storyboards
            and briefs, has face-to-face engagement at its heart while focussing
            on meeting the customers where they are.{" "}
          </p>
        </WorkTextBlock>

        <WorkMultipleImage
          images={[
            "mirvac-storyboard-00003.jpg",
            "mirvac-storyboard-00009.jpg",
            "mirvac-storyboard-00008.jpg",
          ]}
        />

        <RelatedWork exclude="mirvac" />
      </ContentHolder>
    </>
  )
}

export default Mirvac
